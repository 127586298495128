<template lang="pug">
  #copie-pouvoirs
    img.w-100(src="@/assets/pouvoirs/copie/fond.png" srcset="@/assets/pouvoirs/copie/fond@2x.png 2x")
    menu-button
    .personnages(:class="{ duo: personnages.length === 2, single: personnages.length === 1 }")
      .personnage(v-for="(p, i) in personnages" :class="`perso-${i + 1}`")
        img.w-100(:src="require('@/assets/personnages/perso_' + p.replace(/ +/g, '-') + '.png')")
    .text-block.message.p-absolute.text-center.text-bottom
      div(v-if="personnages.length === 2")
        | {{ $t('pouvoirs.copie.duo', { name1: $t('personnages.' + personnages[0]), name2: $t('personnages.' + personnages[1]) }) }}
        | {{ $t('pouvoirs.copie.gagnez-piece-savoir') }}
      div(v-else)
        | {{ $t('pouvoirs.copie.single', { name: $t('personnages.' + personnages[0]) }) }}
        | {{ $t('pouvoirs.copie.gagnez-piece-savoir') }}
    .nuages(:class="{ disparition: animationNuages }")
      img(src="@/assets/pouvoirs/copie/nuage_01.png" srcset="@/assets/pouvoirs/copie/nuage_01@2x.png 2x")
      img.nuage-2(:class="{ animate: animationPetitNuage }" src="@/assets/pouvoirs/copie/nuage_02.png" srcset="@/assets/pouvoirs/copie/nuage_02@2x.png 2x")
    img.puzzle(:class="{ apparition: apparitionPuzzle, deplacement: deplacementPuzzle }" src="@/assets/pouvoirs/copie/puzzle.png" srcset="@/assets/pouvoirs/copie/puzzle@2x.png 2x")
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'

export default {
  name: 'CopiePouvoirEnCours',

  components: {
    MenuButton
  },

  props: {
    names: String
  },

  data() {
    return {
      animationPetitNuage: false,
      animationNuages: false,
      apparitionPuzzle: false,
      deplacementPuzzle: false
    }
  },

  computed: {
    personnages() {
      return this.names.split(/,\s*/)
    },

    zoom() {
      return this.$store.getters.zoom
    },

    styleEncartPersonnage() {
      return {
        width: `${500 * this.zoom}px`,
        height: `${600 * this.zoom}px`
      }
    }
  },

  mounted() {
    this.timers = [
      setTimeout(() => { this.animationPetitNuage = true }, 1000),
      setTimeout(() => { this.animationNuages = true }, 3000),
      setTimeout(() => {
        this.$sound('success')
        this.apparitionPuzzle = true
      }, 3100),
      setTimeout(() => { this.deplacementPuzzle = true }, 3600)
    ]
  },

  beforeDestroy() {
    this.timers.forEach(t => clearTimeout(t))
  },

  methods: {
  }
}
</script>

<style lang="less" scoped>
#copie-pouvoirs {
  #menu-button {
    right: 40px;
    top: 55px;
  }
}
.personnages {
  position: absolute;
  left: 15%;
  top: 33%;

  .personnage {
    position: absolute;
    border: 4px solid black;
    background: white;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 1em 1em 0 1em;
    overflow: hidden;

    img {
      position: absolute;
      bottom: -1em;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: auto;
    }
  }

  &.single {
    width: 30%;
    height: 30%;
    .personnage {
      width: 50%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%) scale(1.5);
    }
  }

  &.duo {
    width: 30%;
    height: 30%;
    .personnage {
      width: 50%;
      height: 100%;
    }

    .perso-2 {
      left: 50%;
      transform: translate(5%, -22%) scale(1.1);
    }
    .perso-1 {
      transform: translate(-5%, 22%) scale(1.1);
    }
  }
}

.message {
  width: 80%;
}

@keyframes animation-nuage {
  from {
    top: 60%;
    transform: scale(0.9) translateX(-50%);
  }
  5% {
    transform: scale(1) translateX(-50%);
  }
  10% {
    transform: scale(0.9) translateX(-50%);
  }
  15% {
    transform: scale(1) translateX(-50%);
  }
  20% {
    transform: scale(0.9) translateX(-50%);
  }
  25% {
    transform: scale(1) translateX(-50%);
    top: 60%;
  }
  to {
    top: 90%;
  }
}

@keyframes apparition-puzzle {
  from {
    transform: scale(0.3);
  }
  80% {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
    opacity: 1;
  }
}

@keyframes deplacement-puzzle {
  from {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(0.8);
  }
}

.puzzle {
  position: absolute;
  left: 55%;
  top: 32%;
  width: 20%;
  height: 40%;
  opacity: 0;
  transform: scale(0.3);
  filter: drop-shadow(0px 0px 20px white);

  &.apparition {
    animation: apparition-puzzle 500ms forwards;
  }
  &.deplacement {
    opacity: 1;
    animation: deplacement-puzzle 1s infinite;
  }
}

.nuages {
  position: absolute;
  left: 51%;
  top: 10%;
  width: 25%;
  height: 37%;
  transition: opacity 300ms ease-in, transform 300ms ease-in;

  &.disparition {
    opacity: 0;
    transform: scale(5);
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
  }
  img.nuage-2 {
    top: 60%;
    transform-origin: center;
  }
  img.nuage-2.animate {
    animation: animation-nuage 2s forwards;
  }
}
</style>
