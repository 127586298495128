<template lang="pug">
  .vue-pouvoir-carson(:class="{ error }")
    copie-pouvoir-en-cours(v-if="solved" names="carson")
    div(v-else)
      img.w-100.p-absolute.bg(src="@/assets/pouvoirs/carson/fond.jpg" srcset="@/assets/pouvoirs/carson/fond@2x.jpg 2x")

      svg.p-absolute(ref="svg" width='100%' height='100%' viewbox='0 0 1920 1080' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;')
        g#boutons(transform='matrix(0.997624,0,0,0.998365,-0.00543122,-9.29646e-17)')
          g#bouton_01(@click="toggle(0)" :class="{ selected: isSelected(0) }")
            path(d='M975.66,28L1806.314,28L1102.263,444L976.886,372L975.66,28Z')
          g#bouton_02(@click="toggle(1)" :class="{ selected: isSelected(1) }")
            path(d='M1115.985,468L1856.373,28L1894.474,28L1894.474,1057L1889.903,1057L1115.985,612L1115.985,468Z')
          g#bouton_03(@click="toggle(2)" :class="{ selected: isSelected(2) }")
            path(d='M1100.949,638L977.665,709L977.665,1057L1832.105,1057L1100.949,638Z')
          g#bouton_04(@click="toggle(3)" :class="{ selected: isSelected(3) }")
            path(d='M950.438,1057L119.48,1057L824.317,638L950.223,712L950.438,1057Z')
          g#bouton_05(@click="toggle(4)" :class="{ selected: isSelected(4) }")
            path(d='M807.979,471L40.507,28L21.392,28L25.712,1057L63.401,1057L808.601,615L807.979,471Z')
          g#bouton_06(@click="toggle(5)" :class="{ selected: isSelected(5) }")
            path(d='M822.714,443L947.699,371L947.342,28L92.84,28L822.714,443Z')
      .numbers
        .number(v-for="n in 6" :id="`number_0${n}`" :class="{ selected: isSelected(n - 1) }") {{sequences[n - 1].values[sequences[n - 1].index]}}

      menu-button
      .text-block.titre {{$t('pouvoirs.carson.titre')}}
      button.validate(@click="validate") {{$t('pouvoirs.carson.valider')}}
</template>

<script>
import Vue from 'vue'
import MenuButton from '@/components/MenuButton.vue'
import CopiePouvoirEnCours from '@/components/CopiePouvoirEnCours.vue'

const SOLUTION = '1,7,17'

export default {
  name: 'VuePouvoirCarson',

  components: {
    MenuButton, CopiePouvoirEnCours
  },

  computed: {
    selection() {
      return this.sequences
        .filter(seq => seq.selected)
        .map(seq => seq.values[seq.index])
        .sort((a, b) => parseInt(a) - parseInt(b))
        .join(',')
    }
  },

  mounted () {
    this.$refs.svg.setAttribute('viewBox', '0 0 1920 1080')
    this.interval = setInterval(() => this.tick(), 1500)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  data () {
    return {
      error: false,
      sequences: [
        { index: 0, selected: false, values: [79, 22, 93, 10, 7] }, // 7 => N
        { index: 0, selected: false, values: [95, 102, 78, 80, 47, 19] },
        { index: 0, selected: false, values: [43, 31, 1, 34, 15, 36] }, // 1 => H
        { index: 0, selected: false, values: [20, 37, 55, 87, 72] },
        { index: 0, selected: false, values: [27, 29, 30, 16, 17, 35, 54] }, // 17 => Cl
        { index: 0, selected: false, values: [37, 24, 83, 84, 92, 94, 17] }
      ],
      solved: false
    }
  },

  methods: {
    toggle(n) {
      this.$sound('click')
      this.sequences[n].selected = !this.sequences[n].selected
    },

    validate() {
      this.solved = this.selection === SOLUTION
      this.error = !this.solved
      this.$sound(this.error ? 'error' : 'ok')
      if (this.error) {
        Vue.nextTick(() => {
          setTimeout(() => this.reset(), 1000)
        })
      }
    },

    reset() {
      this.error = false
      this.sequences.forEach(seq => {
        seq.selected = false
        seq.index = 0
      })
    },

    isSelected (n) {
      return this.sequences[n].selected
    },

    tick() {
      this.sequences.forEach(seq => {
        if (!seq.selected) {
          seq.index = seq.index === seq.values.length - 1 ? 0 : seq.index + 1
        }
      })
    }
  }
}
</script>

<style lang="less">
.vue-pouvoir-carson {
  #menu-button {
    top: 74px;
    right: 50px;
  }
  .validate {
    position: absolute;
    bottom: 50px;
    right: 60px;
  }
  .numbers {
    position: absolute;
    top: 0;
    left: 0;
    .number {
      position: absolute;
      font-size: 60px;
      color: black;
      background: white;
      border: 7px solid black;
      box-sizing: border-box;
      padding: 20px 30px;
      border-radius: 60px;
      transform: translateX(-50%);
      pointer-events: none;
      transition: background 250ms ease, color 250ms ease;
    }
    .number.selected {
      background: #1bbc01;
      color: white;
      border-color: white;
    }
    #number_01, #number_06 { top: 210px }
    #number_01, #number_03 { left: 1170px }
    #number_06, #number_04 { left: 746px }

    #number_02, #number_05 { top: 480px }
    #number_02 { left: 1476px }
    #number_05 { left: 444px }

    #number_03, #number_04 { top: 800px }
  }

  &.error {
    .numbers .number.selected {
      background: rgb(255, 72, 0);
      color: white;
      border-color: white;
    }
    svg #boutons .selected path {
      fill: rgb(255, 72, 0);
      fill-opacity: 1;
    }
  }

  .titre {
    position: absolute;
    top: 50px;
    left: 190px;
    width: 1540px;
  }

  svg {
    #boutons {
      path {
        fill: black;
        fill-opacity: 0;
        stroke:black;
        stroke-width: 7px;
        transition: fill-opacity 250ms ease;
      }
      .selected path {
        fill-opacity: 0.25;
      }
    }
  }
}
</style>
